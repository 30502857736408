<template>
  <v-card style="min-height: calc(100vh);border-radius:0;padding:10px;">
    <div style="padding:20px;" v-if="!tab">
    <h2 style="color:gray;">Configurar URL</h2>
    </div>
    <div v-if="!tab">
      <v-row 
        v-for="(item, index) of tabs"
        :key="item.id"
        no-gutters
        @click="handleSelectMenu(item.id)"
        style="cursor:pointer;"
        :style="{ backgroundColor: index % 2 === 0 ? '#CCCCCC' : '#CCCCCC80' }"
        class="prepare-url-menu"
      >
        <v-col
          cols="12"
          sm="2"
        >
        </v-col>
        <v-col
          cols="12"
          sm="2"
          style="padding:5px;font-size: 1.4rem;"
        >
          <a color="primary"><b>{{item.name}}</b></a>
        </v-col>
        <v-col
          cols="12"
          sm="5"
          style="text-align: left;padding:5px;display: flex;justify-content: flex-start;"
        >
          <div
            v-for="image of item.images"
            :key="image"
            style="padding: 5px;justify-content: flex-start;"
          >
            <v-img
              contain
              max-width="300"
              max-height="200"
              :src="image"
              style="border-radius:5px;"
            />
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="3"
          style="padding:20px;text-align:left;padding:5px;display: flex;justify-content: flex-start;"
        >
          <span v-if="item.info" style="font-style: italic;"><b>INFO: </b>{{item.info}}</span>
        </v-col>
      </v-row>
    </div>
    <!--v-tabs
      background-color="primary"
      center-active
      dark
      next-icon="mdi-arrow-right-bold-box-outline"
      prev-icon="mdi-arrow-left-bold-box-outline"
      show-arrows
      v-model="tab"
    >
      <v-tabs-slider color="black"></v-tabs-slider>

      <v-tab
        v-for="i in tabs"
        :key="i.id"
        :href="'#tab-' + i.id"
      >
        {{ i.name }}
      </v-tab>
    </v-tabs-->
    <activityWeekCard v-if="tab === 'activityWeekCard'" :onBack="() => { handleSelectMenu(null) }" />
    <activityWeekDviewcontent v-else-if="tab === 'activityWeekDviewcontent'" :onBack="() => { handleSelectMenu(null) }" />
    <activityWeHappyNew v-else-if="tab === 'activityWeHappyNew'" :onBack="() => { handleSelectMenu(null) }" />
    <acttivBarceloWeHappy v-else-if="tab === 'acttivBarceloWeHappy'" :onBack="() => { handleSelectMenu(null) }" />
    <activityPlayer v-else-if="tab === 'activityPlayer'" :onBack="() => { handleSelectMenu(null) }" />
    <totem v-else-if="tab === 'totem'" :onBack="() => { handleSelectMenu(null) }" />
    <shows v-else-if="tab === 'showsDviewcontent'" :onBack="() => { handleSelectMenu(null) }" />
    <activityNoCode v-else-if="tab === 'activityNoCode'" :onBack="() => { handleSelectMenu(null) }" />
    <!--v-tabs-items v-model="tab">
      <v-tab-item
        v-for="i in 30"
        :key="i"
        :value="'tab-' + i"
      >
        <v-card flat>
          <v-card-text>{{ i }}</v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items-->
  </v-card>
</template>
<script>
import ActivityWeekCard from './ActivityWeekCard'
import ActivityWeekDviewcontent from './ActivityWeekDviewcontent'
import ActivityWeHappyNew from './ActivityWeHappyNew'
import ActtivBarceloWeHappy from './ActtivBarceloWeHappy'
import ActivityPlayer from './ActivityPlayer'
import Totem from './Totem'
import Shows from './Shows'
import ActivityNoCode from './ActivityNoCode'

import player1 from '@/assets/prepareURLTemplates/player1.png'
import player2 from '@/assets/prepareURLTemplates/player2.png'
import player3 from '@/assets/prepareURLTemplates/player3.png'
import weekNew from '@/assets/prepareURLTemplates/weekNew.png'
import totem from '@/assets/prepareURLTemplates/totem.png'
import weHappyNew from '@/assets/prepareURLTemplates/weHappyNew.png'
import weekOld from '@/assets/prepareURLTemplates/weekOld.png'
import showOld from '@/assets/prepareURLTemplates/showOld.png'
import ext1 from '@/assets/prepareURLTemplates/ext1.png'
import ext2 from '@/assets/prepareURLTemplates/ext2.png'
import acttivBarcelo from '@/assets/prepareURLTemplates/acttivBarcelo.png'

export default {
  components: {
    ActivityWeekCard,
    ActivityWeekDviewcontent,
    ActivityWeHappyNew,
    ActtivBarceloWeHappy,
    ActivityPlayer,
    Totem,
    Shows,
    ActivityNoCode,
  },
  data: () => ({
    tab: null,
  }),
  computed: {
    tabs () {
      return [
        {
          id: 'activityPlayer',
          name: 'Activity player',
          images: [player1, player2, player3],
        },
        {
          id: 'activityWeekCard',
          name: 'Semanal tarjetas NEW',
          images: [weekNew],
        },
        {
          id: 'totem',
          name: 'Totem',
          images: [totem],
        },
        {
          id: 'activityWeHappyNew',
          name: 'WeHappy NEW',
          images: [weHappyNew],
        },
        {
          id: 'acttivBarceloWeHappy',
          name: 'Acttiv Barcel� WeHappy',
          images: [acttivBarcelo],
        },
        {
          id: 'activityWeekDviewcontent',
          name: 'Semanal OLD',
          images: [weekOld],
        },
        {
          id: 'showsDviewcontent',
          name: 'Shows OLD',
          images: [showOld],
        },
        {
          id: 'activityNoCode',
          name: 'M�vil y player vertical (no recomendado)',
          images: [ext1, ext2],
          info: 'usado en pantallas verticales Barcel� y cuando se captura QR para las actividades (cuando no existe webapp)',
        },
      ]
    },
  },
  methods: {
    handleSelectMenu (v) {
      this.tab = JSON.parse(JSON.stringify(v))
    },
  },
}
</script>
<style scoped>
.prepare-url-menu:hover {
  background-color: white !important;
}
</style>

