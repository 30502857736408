<template>
  <div style="padding:10px;">
    <v-btn
      @click="onBack"
      color="primary"
      style="margin: 10px;"
    >
      volver
    </v-btn>
    <v-row no-gutters>
      <v-col
        cols="12"
        sm="2"
        style="padding:5px;"
      >
        <id-selector
          label="ActtivID (hotel)"
          :type="'acttiv'"
          :value="id"
          :onChange="(v) => {
            id = v
            prepareURL()
          }"
        />
        <!--v-text-field
          v-model="id"
          label="ActtivID (hotel)"
          @input="prepareURL"
          hide-details
          outlined
        /-->
      </v-col>
      <v-col
        cols="12"
        sm="2"
        style="padding:5px;"
      >
          <v-autocomplete
            v-model="locale"
            :items="languages"
            outlined
            chips
            small-chips
            label="Idioma"
            itemText="NormalName"
            itemValue="ID"
            hide-details
            @change="prepareURL"
          />
      </v-col>
      <v-col
        cols="12"
        sm="2"
        style="padding:5px;"
      >
        <v-text-field
          v-model="addDays"
          label="A�adir d�a (0-hoy, 1-ma�ana)"
          @input="prepareURL"
          hide-details
          outlined
        />
      </v-col>
      <v-col
        cols="12"
        sm="2"
        style="padding:5px;"
      >
        <v-text-field
          v-model="zoom"
          label="Zoom"
          @input="prepareURL"
          hide-details
          outlined
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        style="text-align:center;padding:20px;"
      >
        <copy-url :url="url" />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        lg="3"
      />
      <v-col
        cols="12"
        sm="12"
        lg="6"
        style="text-align: center !important;"
        class="preview"
      >
          <preview 
            v-if="parentWidth && url"
            :url="url"
            locale="es"
            :size="{
              w: 1920,
              h: 1080
            }"
            :parentWidth="parentWidth"
          />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        lg="3"
      />
    </v-row>
  </div>
</template>
<script>
import utilsService from '@/services/utils'
//import ColorPickerItem from '@/components/common-components/ColorPickerItem'
import IdSelector from './IdSelector'
import Preview from './Preview'
import CopyUrl from './CopyUrl'
export default {
  components: {
    IdSelector,
    Preview,
    CopyUrl,
    //ColorPickerItem,
  },
  props: {
    onBack: {
      type: Function,
      requried: true,
    },
  },
  data: () => ({
    url: null,
    zoom: 100,
    id: null,//'350',
    addDays: null,
    parentWidth: null,
    locale: null,
  }),
  computed: {
    languages () {
      return utilsService.getAllLanguages()
    },
  },
  mounted () {
    setTimeout(() => {
      this.parentWidth = document.getElementsByClassName('preview')[0].offsetWidth - 0
    }, 1000)
    this.prepareURL()
  },
  methods: {
    prepareURL () {
      if (!this.id) {
        this.url = null
        return
      }
      this.id = this.id.split(' ').pop('')
      //this.url = 'https://www.dviewcontent.com/activity/acttiv_barcelo/?hotel=2535&AddDays=0'
      this.url = 'https://www.dviewcontent.com/activity/acttiv_barcelo/?'
        .concat(`&hotel=${this.id}`)
        .concat(this.addDays && this.addDays.length > 0 ? `&AddDays=${this.addDays}`: '')
        .concat(this.zoom && this.zoom.length > 0 && this.zoom !== '100' ? `&Zoom=${this.zoom}`: '')
        .concat(this.locale && this.locale.length > 0 ? `&locale=${this.locale}`: '')
    },
  },
  /*
  ("hotel")
  ("Zoom")
  ("time")
  ("Usuario")
  ("items")
  ("AddDays")
  ("date")
  ("showOld")
  ("locale")
  */
}
</script>

