<template>
  <div style="padding:10px;">
    <v-btn
      @click="onBack"
      color="primary"
      style="margin: 10px;"
    >
      volver
    </v-btn>
    <v-row no-gutters>
      <v-col
        cols="12"
        sm="3"
        style="padding:5px;"
      >
        <id-selector
          label="DviewcontentID (NEW [contentManager])"
          type="cmdviewcontent"
          :value="id"
          :onChange="(v) => {
            id = v
            prepareURL()
          }"
        />
        <!--v-text-field
          v-model="id"
          label="DviewcontentID (NEW [contentManager])"
          @input="prepareURL"
          hide-details
          outlined
        /-->
      </v-col>
      <v-col
        cols="12"
        sm="2"
        style="padding:5px;"
      >
        <v-text-field
          v-model="meteoID"
          label="Meteo"
          @input="prepareURL"
          hide-details
          outlined
        />
      </v-col>
      <v-col
        cols="12"
        sm="2"
        style="padding:5px;"
      >
        <v-text-field
          v-model="zoom"
          label="Zoom"
          @input="prepareURL"
          hide-details
          outlined
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        style="text-align:center;padding:20px;"
      >
        <copy-url :url="url" />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        lg="3"
      />
      <v-col
        cols="12"
        sm="12"
        lg="6"
        style="text-align: center !important;"
        class="preview"
      >
          <preview 
            v-if="parentWidth && url"
            :url="url"
            locale="es"
            :size="{
              w: 1920,
              h: 1080
            }"
            :parentWidth="parentWidth"
          />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        lg="3"
      />
    </v-row>
  </div>
</template>
<script>
import utilsService from '@/services/utils'
//import ColorPickerItem from '@/components/common-components/ColorPickerItem'
import IdSelector from './IdSelector'
import Preview from './Preview'
import CopyUrl from './CopyUrl'
export default {
  components: {
    IdSelector,
    Preview,
    CopyUrl,
    //ColorPickerItem,
  },
  props: {
    onBack: {
      type: Function,
      requried: true,
    },
  },
  data: () => ({
    url: null,
    zoom: 100,
    id: null,//'d22f7b82-f550-4f60-a91b-1fba6bae6a99',
    meteoID: 'Santa_Cruz',
    parentWidth: null,
  }),
  computed: {
    types () {
      return [
        {
          id: '0',
          name: 'Hoy'
        },
        {
          id: '1',
          name: 'Lunes'
        },
      ]
    },
    languages () {
      return utilsService.getAllLanguages()
    },
  },
  mounted () {
    setTimeout(() => {
      this.parentWidth = document.getElementsByClassName('preview')[0].offsetWidth - 0
    }, 1000)
    this.prepareURL()
  },
  methods: {
    prepareURL () {
      if (!this.id) {
        this.url = null
        return
      }
      this.id = this.id.split(' ').pop('')
      //this.url = 'https://widgets.dviewcontent.com/cm-totem-activity-barcelo/?id=d22f7b82-f550-4f60-a91b-1fba6bae6a99&meteoID=Santa_Cruz&lmu=2024-12-10T13:47:58.555Z'
      this.url = 'https://widgets.dviewcontent.com/cm-totem-activity-barcelo/?'
        .concat(`&id=${this.id}`)
        .concat(this.meteoID && this.meteoID.length > 0 ? `&meteoID=${this.meteoID}`: '')
        .concat(this.zoom && this.zoom.length > 0 && this.zoom !== '100' ? `&zoom=${this.zoom}`: '')
    },
  },
}
</script>

