<template>
  <div style="padding:10px;">
    <v-btn
      @click="onBack"
      color="primary"
      style="margin: 10px;"
    >
      volver
    </v-btn>
    <v-row no-gutters>
      <v-col
        cols="12"
        sm="6"
        lg="8"
        style="padding:5px;"
      >
        <v-row no-gutters>
          <v-col
            cols="12"
            sm="5"
            style="padding:5px;"
          >
            <id-selector
              label="Dtouch (subdominio)"
              type="dtouchSubdomain"
              :value="id"
              :onChange="(v) => {
                id = v
                prepareURL()
              }"
            />
            <!--v-text-field
              v-model="id"
              label="Subdominio"
              @input="prepareURL"
              hide-details
              outlined
            /-->
          </v-col>
          <v-col
            cols="12"
            sm="2"
            style="padding:5px;"
          >
            <v-checkbox
              v-model="isPC"
              label="PC"
              @change="prepareURL"
              hide-details
              outlined
            />
          </v-col>
          <v-col
            cols="12"
            sm="2"
            style="padding:5px;"
          >
            <v-checkbox
              v-model="isInverted"
              label="Invertido"
              @change="prepareURL"
              hide-details
              outlined
            />
          </v-col>
          <v-col
            cols="12"
            sm="3"
            style="padding:5px;"
          >
            <v-text-field
              v-model="screensaverTime"
              label="Tiempo protector de pantalla (milisegundos)"
              @input="prepareURL"
              hide-details
              outlined
            />
          </v-col>
          <v-col
            cols="12"
            sm="12"
            style="text-align:center;padding:20px;"
          >
            <copy-url :url="url" />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        lg="4"
        style="text-align: center !important;"
        class="preview"
      >
          <preview 
            v-if="parentWidth && url"
            :url="url"
            locale="es"
            :size="{
              w: 1080,
              h: 1920
            }"
            :parentWidth="parentWidth"
          />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import utilsService from '@/services/utils'
//import ColorPickerItem from '@/components/common-components/ColorPickerItem'
import IdSelector from './IdSelector'
import Preview from './Preview'
import CopyUrl from './CopyUrl'
export default {
  components: {
    IdSelector,
    Preview,
    CopyUrl,
    //ColorPickerItem,
  },
  props: {
    onBack: {
      type: Function,
      requried: true,
    },
  },
  data: () => ({
    url: null,
    id: null,
    screensaverTime: '300000',
    isPC: true,
    isInverted: false,
    parentWidth: null,
  }),
  computed: {
    types () {
      return [
        {
          id: '0',
          name: 'Hoy'
        },
        {
          id: '1',
          name: 'Lunes'
        },
      ]
    },
    languages () {
      return utilsService.getAllLanguages()
    },
  },
  mounted () {
    setTimeout(() => {
      this.parentWidth = document.getElementsByClassName('preview')[0].offsetWidth - 0
    }, 1000)
    this.prepareURL()
  },
  methods: {
    prepareURL () {
      if (!this.id) {
        this.url = null
        return
      }
      this.id = this.id.split(' ').pop('')
      this.url = `https://${this.id}.dtouch.es/#/totem?fullscreen=${this.isPC ? 'true' : 'false'}&isInverted=${this.isInverted ? 'true' : 'false'}&screensaverTime=${this.screensaverTime && this.screensaverTime.length > 0 ? this.screensaverTime : 300000}`
    },
  },
}
</script>

