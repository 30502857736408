<template>
  <div style="padding:10px;">
    <v-btn
      @click="onBack"
      color="primary"
      style="margin: 10px;"
    >
      volver
    </v-btn>
    <v-row no-gutters>
      <v-col
        cols="12"
        sm="3"
        style="padding:5px;"
      >
        <id-selector
          label="DviewcontentID (OLD)"
          type="dviewcontentOldName"
          :value="id"
          :onChange="(v) => {
            id = v
            prepareURL()
          }"
        />
        <!--v-text-field
          v-model="id"
          label="Nombre gestor [NO ID])"
          @input="prepareURL"
          hide-details
          outlined
        /-->
      </v-col>
      <v-col
        cols="12"
        sm="2"
        style="padding:5px;"
      >
          <v-select
            v-model="typeID"
            :items="types"
            outlined
            label="Empieza en"
            itemText="name"
            itemValue="id"
            hide-details
            @change="prepareURL"
          />
      </v-col>
      <v-col
        cols="12"
        sm="2"
        style="padding:5px;"
      >
        <v-text-field
          v-model="rows"
          label="Filas"
          @input="prepareURL"
          hide-details
          outlined
        />
      </v-col>
      <v-col
        cols="12"
        sm="2"
        style="padding:5px;"
      >
        <v-text-field
          v-model="category"
          label="Tipo actividad (1,2,3)"
          @input="prepareURL"
          hide-details
          outlined
        />
      </v-col>
      <v-col
        cols="12"
        sm="2"
        style="padding:5px;"
      >
          <v-autocomplete
            v-model="locale"
            :items="languages"
            outlined
            chips
            small-chips
            label="Idioma"
            itemText="NormalName"
            itemValue="ID"
            hide-details
            @change="prepareURL"
          />
      </v-col>
      <v-col
        cols="12"
        sm="2"
        style="padding:5px;"
      >
        <v-text-field
          v-model="addWeek"
          label="A�adir semana (0-actual, 1-pr�xima semana)"
          @input="prepareURL"
          hide-details
          outlined
        />
      </v-col>
      <v-col
        cols="12"
        sm="2"
        style="padding:5px;"
      >
        <v-text-field
          v-model="zoom"
          label="Zoom"
          @input="prepareURL"
          hide-details
          outlined
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        style="text-align:center;padding:20px;"
      >
        <copy-url :url="url" />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        lg="3"
      />
      <v-col
        cols="12"
        sm="12"
        lg="6"
        style="text-align: center !important;"
        class="preview"
      >
          <preview 
            v-if="parentWidth && url"
            :url="url"
            locale="es"
            :size="{
              w: 1920,
              h: 1080
            }"
            :parentWidth="parentWidth"
          />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        lg="3"
      />
    </v-row>
  </div>
</template>
<script>
import utilsService from '@/services/utils'
//import ColorPickerItem from '@/components/common-components/ColorPickerItem'
import IdSelector from './IdSelector'
import Preview from './Preview'
import CopyUrl from './CopyUrl'
export default {
  components: {
    IdSelector,
    Preview,
    CopyUrl,
    //ColorPickerItem,
  },
  props: {
    onBack: {
      type: Function,
      requried: true,
    },
  },
  data: () => ({
    typeID: '0',
    url: null,
    zoom: 100,
    id: null,//'Demo_DViewGUEST',
    locale: 'es',
    addWeek: 0,
    category: null,
    parentWidth: null,
    rows: 8,
  }),
  computed: {
    types () {
      return [
        {
          id: '0',
          name: 'Hoy'
        },
        {
          id: '1',
          name: 'Lunes'
        },
      ]
    },
    languages () {
      return utilsService.getAllLanguages()
    },
  },
  mounted () {
    setTimeout(() => {
      this.parentWidth = document.getElementsByClassName('preview')[0].offsetWidth - 0
    }, 1000)
    this.prepareURL()
  },
  methods: {
    handleChangeColor2 (v) {
      this.color2 = v
      this.prepareURL()
    },
    handleChangeColor1 (v) {
      this.color1 = v
      this.prepareURL()
    },
    prepareURL () {
      if (!this.id) {
        this.url = null
        return
      }
      this.id = this.id.split(' ').pop('')
      //this.url ='https://www.dviewcontent.com/activity/index.php?Zoom=41&Semana=1&Idioma=ES&Semana=0&Filas=8&Usuario=dview&AddSemana=0&Clave=59b90e1005a220e2ebc542eb9d950b1e'
      this.url ='https://www.dviewcontent.com/activity/index.php?Clave=59b90e1005a220e2ebc542eb9d950b1e'
        .concat(this.rows && this.rows.length > 0 ? `&Filas=${this.rows}`: '&Filas=8')
        .concat(`&Usuario=${this.id}`)
        .concat(`&Semana=${this.typeID}`)
        .concat(this.zoom && this.zoom.length > 0 && this.zoom !== '100' ? `&Zoom=${this.zoom}`: '')
        .concat(`&Idioma=${this.locale.toUpperCase()}`)
        .concat(`&AddSemana=${this.addWeek ? this.addWeek : 0}`)
        .concat(this.category && this.category.length > 0 ? `&Act=${this.category}`: '')
    },
  },
}
</script>

